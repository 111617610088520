import React from "react";
import GoogleSignIn from "../img/btn_google_signin_dark_pressed_web.png";
import { auth } from "../firebase";

import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
const Welcome = () => {
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
};

  return (
   
        <main className="welcome">
        <h2>Welcome to MeetUp.</h2>
        <img src="logicko.png" alt="ReactJs logo" className="picture" width={50} height={50} />
        <p>A chatting application developed by AcO Group</p>
        <button className="sign-in">
          <img
            onClick={googleSignIn}
            src={GoogleSignIn}
            alt="sign in with google"
            type="button"
  
          />
        </button>
      </main>
    

  );
};

export default Welcome;