import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBnZX-MlST1cdRLdyT05b6DMpfVq1ew6GA",
  authDomain: "meetup-7893c.firebaseapp.com",
  projectId: "meetup-7893c",
  storageBucket: "meetup-7893c.appspot.com",
  messagingSenderId: "422121563265",
  appId: "1:422121563265:web:27aaccae5dab0102049980",
  measurementId: "G-2ZKD900J6D"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);